import {type Theme, DefaultTheme} from '@react-navigation/native';

export const Colors = {};

export const defaultTheme: Theme = {
  dark: false,
  colors: {
    ...DefaultTheme.colors,
    text: 'white',
  },
};
