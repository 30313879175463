import type {StackScreenProps} from '@react-navigation/stack';

export enum MainScreens {
  AvatarSelector = 'AvatarSelector',
  ProfileAvatar = 'ProfileAvatar',
  NotFound = 'NotFound',
}

export type MainStackParamList = {
  [MainScreens.AvatarSelector]: {
    id: string;
    accessToken: string;
    avatarId: string;
    gender: string;
  };
  [MainScreens.ProfileAvatar]: {
    url: string;
    gender: 'male' | 'female';
    other?: string;
  };
  [MainScreens.NotFound]: undefined;
};

export type AvatarSelectorScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.AvatarSelector
>;

export type ProfileAvatarScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.ProfileAvatar
>;

export type NotFoundScreenParams = StackScreenProps<
  MainStackParamList,
  MainScreens.NotFound
>;
