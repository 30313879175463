import {MainScreens} from 'types/navigation-types';

import type {LinkingOptions} from '@react-navigation/native';

export const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: ['http://localhost:3000/', 'http://127.0.0.1:5501/'],
  config: {
    screens: {
      [MainScreens.AvatarSelector]: {
        path: '/:id',
      },
      [MainScreens.ProfileAvatar]: {
        path: '/profile',
      },

      [MainScreens.NotFound]: '*',
    },
  },
};
