import {AppRegistry} from 'react-native';
import App from './App';

// Generate the required CSS
import FuturaBkBook from './assets/fonts/FuturaBkBook.ttf';
import FuturaHeavyBT from './assets/fonts/FuturaHeavyBT.ttf';

const futuraBookFontStyles = `@font-face {
  src: url(${FuturaBkBook});
  font-family: FuturaBkBook;
}`;

const futuraHeavyFontStyles = `@font-face {
    src: url(${FuturaHeavyBT});
    font-family: FuturaHeavyBT;
  }`;

// Create a stylesheet
const style = document.createElement('style');
style.type = 'text/css';

// Append the iconFontStyles to the stylesheet
if (style.styleSheet) {
  style.styleSheet.cssText = futuraBookFontStyles;
} else {
  style.appendChild(document.createTextNode(futuraBookFontStyles));
  style.appendChild(document.createTextNode(futuraHeavyFontStyles));
}

// Inject the stylesheet into the document head
document.head.appendChild(style);

const appName = 'SpilzAvatarCreator';

AppRegistry.registerComponent(appName, () => App);

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
});
