import React, {lazy} from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {MainScreens, type MainStackParamList} from '@/types/navigation-types';
import withLazy from '@/utils/withLazy';

const AvatarSelector = lazy(
  () =>
    import(/* webpackChunkName: "AvatarSelector" */ '@/screens/AvatarSelector'),
);

const ProfileAvatar = lazy(
  () =>
    import(/* webpackChunkName: "ProfileAvatar" */ '@/screens/ProfileAvatar'),
);

const MainStack = createStackNavigator<MainStackParamList>();

const MainNavigator = () => {
  return (
    <MainStack.Navigator
      screenOptions={{cardStyle: {flex: 1, backgroundColor: '#000'}}}>
      <MainStack.Screen
        options={{headerShown: false, title: 'Avatar Selector'}}
        name={MainScreens.AvatarSelector}
        component={withLazy(AvatarSelector)}
      />
      <MainStack.Screen
        options={{headerShown: false, title: 'User Avatar'}}
        name={MainScreens.ProfileAvatar}
        component={withLazy(ProfileAvatar)}
      />
    </MainStack.Navigator>
  );
};

export default MainNavigator;
