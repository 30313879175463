import React, {type FC} from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {linking} from '@/utils/navigation-options';
import MainNavigator from '@/navigators/MainNavigator';
import {defaultTheme} from './utils/colors';

const App: FC = () => {
  return (
    <NavigationContainer theme={defaultTheme} linking={linking}>
      <MainNavigator />
    </NavigationContainer>
  );
};

export default App;
